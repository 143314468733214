@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

a {
  text-decoration: none !important;
}

body {
  overflow: auto !important;
}
body, html{
  overflow: auto !important;
}

::-webkit-scrollbar {
  display: none;
}

/* <-----LogIn Start-----> */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.font_family_nunito {
  font-family: "Nunito", sans-serif;
}

.authentication-login {
  box-sizing: border-box;
  width: 448px;
  height: 100vh;
  flex-shrink: 0;
  padding-bottom: 100px;
  font-family: "Nunito";
}

.login-bg {
  background: #f7f7f8;
}

.login-form {
  height: 100vh;
  flex-shrink: 0;
  background: #fff;
}

.page-wrapper {
  background: #f7f7f8;
  /* width: 1440px;
height: 960px; */
}

.logo {
  width: 35px;
  height: 28px;
  flex-shrink: 0;
  margin: 20px;
}
.settingLogo {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 50px;
  margin-right: 8px;
  object-fit: cover;
}

.myot {
  color: #000;
  font-family: Nunito;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}

.login-desc {
  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  padding-bottom: 30px;
  margin-top: -15px;
}

.img-login {
  margin-left: 200px;
  width: 585.418px;
  height: 557.422px;
  flex-shrink: 0;
  padding: inherit;
}

.btn {
  background-color: #1a81ff;
  color: #fff !important;
  font-weight: 600 !important;
  width: 100%;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  border-radius: 4px;
  padding: 10px 35px !important;
  display: inline-flex;
  font-size: 15px;
  transition: 0.5s all;
  border: 2px solid #ffffff !important;
  box-shadow: 0 0 20px #0000001a;
  text-transform: uppercase !important;
}

.btn:hover {
  background-color: #fff !important;
  color: #1a81ff !important;
  border: 2px solid #1a81ff !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.download-btn {
  width: 106px;
  height: 39px;
  align-items: center;
  background-color: #3e51ff;
  color: white;
  border-radius: 10px;
  font-size: 14px;
  border: none;
}
.view-details {
  width: 1168px;
  margin-left: 20px;
  height: 850px;
  border: 2px solid gray;
  border-radius: 10px;
}
.jLogo {
  width: 30px;
  height: 44px;
  margin-left: 50px;
}
.preview {
  font-size: 24px;
  font-weight: 700;
}
.view-text-1 {
  width: 231px;
  height: 51px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  margin-left: 50px;
  margin-top: 20px;
  color: #000;
}
.view-text-2 {
  width: 1108px;
  height: 268px;
  font-size: 12px;
  font-weight: 400;
  /* line-height: 20px; */
  margin-left: 50px;
  margin-top: 20px;
  color: #000;
}
.sign {
  width: 89px;
  height: 55px;
  margin-left: 50px;
  margin-top: -80px;
}
.sign-2 {
  margin-left: 950px;
}
.received-docs-title {
  font-size: 16px;
  font-weight: 600;
}
.received-docs {
  /* border: 2px solid gray; */
  padding: 6px;
  border-radius: 10px;
  background-color: #fafafb;
  width: 950px;
  height: 900px;
}
.docs-info {
  background-color: #fff;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 20px;
  border-radius: 10px;
}
.img_profile {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  object-fit: cover;
  margin-left: 20px;
  margin-right: 10px;
}
.docs-data {
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
}
.body-data {
  margin-top: -14px;
  font-size: 14px;
  font-weight: 600;
}
.head-data {
  font-size: 10px;
  margin-top: 10px;
}
.comment {
  margin-top: 20px;
  font-size: 12px;
}
.form-login {
  padding: inherit;
}

.form-control {
  height: 50px;
  flex-shrink: 0;
  background-color: #ffffff;
  stroke-width: 1px;
  /* stroke: #e0e2e7; */
  box-shadow: none !important;
}

.form-lable {
  color: #000;
  /* font-family: Nunito; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.font_nunito {
  font-family: "Nunito" !important;
}
.remember {
  display: flex;
  padding-left: 0px !important;
  /* background-color: #000; */
  margin-left: -10px;
}

.remember-me {
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reset-password {
  color: #605bff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

/* <-----LogIn End-----> */

/* <-----Forgot password Start-----> */

.reset-pass-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-shrink: 0;
  /* border: none; */
}

.card-forgot {
  border-radius: 25px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* justify-content: space-evenly; */
  align-items: center;
  /* text-align: center; */
  width: 650px;
  height: 600px;
  padding: 100px;
}

.recover-text {
  color: #000;
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 50px;
}

.reset-pass-desc {
  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: -15px;
  margin-bottom: 50px;
}

.form-reset {
  width: 502px;
  height: 50px;
  margin-bottom: 20px;
}

.reset-success {
  width: 169px;
  height: 50px;
  flex-shrink: 0;
}

/* <-----Forgot password End-----> */

/* <-----Home page start-----> */

.home-page {
  width: 1440px;
  height: 1047px;
  background-color: #fff;
}

.middle-section {
  display: flex;
  width: 944px;
  height: 100vh;
}

.left-sidebar {
  width: 212px;
}

.sidebar {
  /* position: sticky; */
  /* top: 0; */
  /* left: 0; */
  /* z-index: 1; */
  overflow-y: auto;
  height: 100%;
  max-height: 100vh;
  /* scrollbar-color: transparent; */
  /* overflow-y: auto; */
  /* flex-shrink: 0; */
  background-color: #fff;
}

.nav-link {
  white-space: nowrap;
}

.middle-content {
  height: 100%;
  overflow-y: auto;
  max-height: 100vh;
  scroll-behavior: smooth;
  scrollbar-color: white;
  position: relative;
}

.sidebarnav {
  display: flex;
  width: 944px;
  height: 72px;
  padding: 22px 184px 22px 600px;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}

.card {
  width: 202px;
  height: 112px;
  flex-shrink: 0;
}
.card-content {
  width: 180px;
  height: 0px;
  color: #051036;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.table-name {
  color: #051036;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.table-searchbar-txt {
  font-family: Nunito, "Times New Roman", Times, serif !important;
}
.table-searchbar {
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  /* width: 892px; */
  padding: 2px;
  /* align-items: center; */
  /* gap: 16px; */
  border-radius: 8px;
  background: #f7f9fb;
  align-content: center;
  height: 50px;
}
/* .user_table td {
  margin: 0 !important;
  padding: 10px 0 !important;
} */
.hide-selected {
  display: flex;
  height: 28px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.05);
  margin-left: 10px !important;
}
.table-searchbar-img {
  cursor: pointer !important;
}

.search-bar {
  display: flex;
  width: 160px;
  height: 28px;
  padding: 4px 6px;
  align-items: center;
  border: none;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.05);
}

.table-search-bar {
  display: flex;
  width: 160px;
  height: 28px;
  padding: 4px 6px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
}

/*  */

.checkbox-table {
  width: 12px;
  height: 12px;
  margin: 3px 4px;
}

.table-head {
  display: flex;
  /* width: auto; */
  height: 37px;
  /* align-items: center; */
  border-bottom: solid rgba(0, 0, 0, 0.4);
  /* border-style: solid; */
  border-width: 2px;
  /* border- */
}
.table-head-dashboard {
  display: flex;
  width: auto;
  height: 37px;
  width: 892px;
  align-items: center;
}

.th-text {
  color: rgb(0, 0, 0);
  opacity: 1;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 18px; /* 150% */
}

.td-text {
  color: #051036;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  text-align: left;
}
.table-row {
  width: 840px;
}

.right-sidebar {
  padding: 20px;
  height: 100%;
  width: 300px;
  /* height: 1047px; */
  flex-shrink: 0;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  overflow: scroll;
  background-color: #fff;
  border-left: solid rgb(213, 212, 212) 1px;
}

.app-header {
  display: flex;
  width: 500px;
  height: 72px;
  margin-left: -25px;
  padding: 0;
  background-color: transparent;
  /* padding: 22px 184px 22px 600px; */
  /* justify-content: flex-start; */
  /* align-items: center; */
  /* flex-shrink: 0; */
}

.management {
  border-radius: 16px;
  margin-left: 0px;
  width: 100%;
  padding-top: 20px;
  /* justify-content: space-around; */
  border-radius: 16px;

  text-align: center;
}
.management-text {
  margin-top: 10px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.05);
  display: inline-flex;
  height: 28px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.card-1 {
  border-radius: 16px;
  background: #f7f9fb;
  width: 432px;
  height: 250px;
  flex-shrink: 0;
  margin-bottom: 20px;
}

.card-2 {
  width: 202px;
  height: 250px;
  background: #fff4ea;
  border-radius: 16px;
}
.card-3 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 202px;
  height: 250px;
  border-radius: 16px;
  background: #f7f9fb;
}

.cardss {
  width: 892px;
}

.text-card {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-top: -15px;
  font-family: nunito;
}

.calendar-screen {
  width: 1100px;
  background: #fafafb;
}

.mini-calendar {
  width: 206px;
  height: 222px;
  flex-shrink: 0;
}

.calendar-section {
  width: 1228px;
  /* margin-left: -100px; */
  background: #fafafb;
}

.calendar-header {
  margin-left: -60px;
  width: calc(100% - 200px);
  background-color: white;
}

.users-table {
  width: 944px;
}

.calendar-btn {
  width: 206px;
  height: 45px;
  flex-shrink: 0;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  border-radius: 10px;
  background: #3e51ff;
}

.header-card-text {
  color: #051036;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.table-body-desc {
  display: flex;
  color: #051036;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  /* align-content: center; */
  align-items: center;
}

.navbar {
  display: flex;
  width: 944px;
  height: 72px;
  padding: 22px 184px 22px 600px;
  justify-content: flex-end;
  align-items: center;
  /* flex-shrink: 0; */
  border-bottom: 1px rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-left: -30px;
}

.page-navigation {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.table-tasks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.table-tasks > tr > th {
  display: flex;
  width: 210px;
  height: 43px;
  padding: 8px 16px 8px 0px;
  align-items: center;
  flex-shrink: 0;
}

.sidebar-btn {
  color: #000;
  font-feature-settings: "salt" on, "liga" off;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  white-space: nowrap;
}

.sidebar-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.side-icon2 {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.statics_box {
  white-space: nowrap;
  overflow: hidden;
  /* box-shadow: 0px 0px 8px 0px #D0D0D880; */
  background-color: #fff;
  /* height: 124px; */
  /* display: flex; */
  align-items: flex-start;
  border-radius: 14px;
  padding: 18px 20px;
  justify-content: space-between;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.dashboard-card {
  /* height: 225px; */
  /* height: 100%; */
  white-space: nowrap;
  overflow: hidden;
  background-color: #fff;
  align-items: flex-start;
  border-radius: 14px;
  padding: 18px 20px;
  justify-content: space-between;
}
.dash_card{
  padding: 18px 0px;
}
.dashboard-card2 {
  white-space: nowrap;
  overflow: hidden;
  height: 100%;
  background: #fff4ea;
  align-items: flex-start;
  border-radius: 14px;
  padding: 18px 20px;
  justify-content: space-between;
}
.dashboard-card3 {
  white-space: nowrap;
  overflow: hidden;
  height: 100%;
  background-color: #fff;
  align-items: flex-start;
  border-radius: 14px;
  padding: 35px 20px;
  justify-content: space-between;
}

.dashboard-card-img {
  width: 100%;
  height: auto;
}
.dashboard-card3 > table {
  height: inherit;
}

.dashboard-card-text {
  color: #000;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.dashboard-card2-text {
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.dashboard-card3-text {
  color: #000;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.statics_icon span img {
  max-height: 28px;
}
.statics_left strong {
  display: block;
  color: #3a3a3d;
  font-size: 25px;
  font-weight: bold;
  line-height: 29.3px;
  margin-bottom: 14px;
}
.statics_left span {
  font-size: 16px;
  color: #8a8b9f;
  text-transform: uppercase;
  line-height: 18.75px;
  display: block;
}

.card-text-count {
  color: #051036;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 36px; /* 150% */
}

.card-insights {
  color: #051036;
  text-align: right;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.card-clr-1-3 {
  background: #e3f5ff;
}
.card-clr-2-4 {
  background: #e5ecf6;
}

.table_comman .table {
  /* display: flex; */
  width: 842px;
  flex-direction: column;
  align-items: flex-start;
  /* box-shadow: 0px 0px 8px 0px #d0d0d880; */
  margin: 0;
  border-radius: 14px;
}
.table_comman tr th {
  padding: 16px 22px;
  /* background-color: #F0F1FF; */
  font-size: 16px;
  /* color: #282C36; */
  text-align: center;
  /* border: 0; */
  white-space: nowrap;
  text-transform: capitalize;
}
.table_comman tr th:first-child {
  border-radius: 14px 0 0 0;
  text-align: left;
}
.table_comman tr th:last-child {
  border-radius: 0 14px 0 0;
}
.table_comman .table-responsive {
  /* box-shadow: 0px 0px 8px 0px #d0d0d880; */
  border-radius: 14px;
}
.table_comman tbody tr td {
  padding: 0px;
  padding-left: 30px;
  background-color: #fff;
  font-size: 15px;
  /* color: #6E7594; */
  text-align: left;
  /* border: 0; */
  /* font-weight: 500; */
  vertical-align: middle;
  white-space: nowrap;
  /* border-bottom: 1px solid #e2e2ed; */
}

.table_comman tbody tr td:first-child {
  text-align: left;
}
td {
  /* text-transform: capitalize !important; */
  vertical-align: middle !important;
}
/* .table_comman tbody tr td:nth-child(2){
  text-align: left;
} */
.table_comman tbody tr:last-child td:first-child {
  border-radius: 0 0 0 14px;
}
.table_comman tbody tr:last-child td:last-child {
  border-radius: 0 0 14px 0;
}
.table_comman .table-responsive::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  border-radius: 50%;
}
.table_comman .table-responsive::-webkit-scrollbar-thumb {
  /* background: #FF7622; */
  border-radius: 50px;
}
.table_comman .table-responsiv::-webkit-scrollbar-thumb {
  background: #ff7622;
  border-radius: 50px;
}
.table_comman .table-responsive::-webkit-scrollbar-thumb:hover {
  background: #ff7622;
}
.right-sidebar-item {
  padding-left: 3px;
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.right-sidebar-heading {
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.profile {
  margin-top: 20px;
  display: flex;
  /* justify-content: space-between; */
}

.footer {
  display: flex;
  justify-content: space-between;

  height: 72px;
  color: #00000066;
  align-items: center;
  flex-shrink: 0;

  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.sidebar-time {
  padding-right: 8px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.4);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Nunito;
  font-size: 14px;
  position: absolute;
  right: 0;
}

.day-time {
  white-space: nowrap;
  padding-top: 20px;
  /* margin: auto; */
  color: #030229;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.6;
  /* height: 40px; */
}

.current-day {
  color: #030229;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.event-9am {
  margin: auto;
  padding: 10px;
  text-align: center;
  width: 163px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ff8f6b;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.event-12pm {
  margin: auto;
  padding: 10px;
  text-align: center;
  width: 163px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #26c0e2;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.event1-4pm {
  margin: auto;
  padding: 10px;
  text-align: center;
  width: 163px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #3a974c;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.event2-4pm {
  margin: auto;
  padding: 10px;
  text-align: center;
  width: 163px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ff8f6b;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.event-11pm {
  margin: auto;
  padding: 10px;
  text-align: center;
  width: 163px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #605bff;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.users-table {
  height: 400px;
}

.mini-calendar {
  border: none;
  color: #000;
  text-decoration: none;
  text-align: center;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 206px;
  height: 222px;
  border-radius: 7px;
  border: 1px solid #fafafb;
  flex-shrink: 0;
}

.create-task-btn {
  border: none;
  width: 206px;
  height: 45px;
  /* flex-shrink: 0; */
  border-radius: 10px;
  background: #3e51ff;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dropdown-item {
  color: #000;

  /* 14 Regular */
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 142.857% */
}

.dropdown-menu {
  width: auto;
  /* height: 146px; */
  flex-shrink: 0;
}

.comment-img {
  margin: 8px;
  width: 20px;
  /* height: 20px; */
  flex-shrink: 0;
}
.comment-btn {
  display: inline-flex;
  padding: 10px 20px;
  border: none;
  color: white;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #3e51ff;
}

.modal-dialog-users {
  width: 1144px;
  height: 872px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #eff4fa;
  background: #fff;
  max-width: auto;
}

@media (min-width: 576px) {
  .modal-dialog-users {
    max-width: none;
    margin-right: auto;
    margin-left: auto;
  }
}
.modal-input {
  /* width: inherit; */
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #eff4fa;
  background: #fff;
}
.user-modal-btn {
  color: #fff;
  border: none;
  width: 88px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #3e51ff;
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
}
.user-modal-btn2 {
  /* color: #fff; */
  border: none;
  width: 88px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 8px;
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  background: transparent;
  font-weight: 400;
  line-height: 16px;
}

.modal-dialog-department {
  width: 670px;
}
@media (min-width: 576px) {
  .modal-dialog-department {
    max-width: none;
    margin-right: auto;
    margin-left: auto;
  }
}

.weekdays {
  color: #030229;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.day {
  border-right: solid 1px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 127px;
  flex-shrink: 0;
  color: #030229;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.7;
  text-align: center;
}
.prev-day {
  border-right: solid 1px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 127px;
  flex-shrink: 0;
  color: #030229;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.3;
}
.next-day {
  border-right: solid 1px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 127px;
  flex-shrink: 0;
  color: #030229;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.3;
}

.today {
  border-right: solid 1px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 127px;
  flex-shrink: 0;
  color: #ff8f6b;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.event-1 {
  margin: 2px;
  padding: 3px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #26c0e2;
  color: #fff;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.event-2 {
  margin: 2px;
  padding: 3px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #ef37ff;
  color: #fff;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.event-3 {
  margin: 2px;
  padding: 3px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #ff8f6b;
  color: #fff;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.event-more {
  padding: 3px;
  margin: 2px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #3e97ff;
  /* opacity: 0.1; */
  color: #3e97ff;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.active_class {
  border-top: solid 2px #ff8f6b;
}
.nav_active {
  background: #ebf5ed;
  border-radius: 15px;
  transition: all 0.5s ease;
}

.nav_active .sidebar-btn {
  color: #3e51ff !important;
  font-weight: bold;
}

.day-year {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.prev-day-year {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.4;
}

.header-chats {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header-chats img {
  width: 32px;
  height: 32px;
}

.search-box-chats {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  height: 44px;
  background: #f7f7f8;
}

.chats-searchbar {
  background-color: #f7f7f8;
  border: none;
  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none !important;
  outline: none !important;
}
.chats-searchbar2 {
  background-color: #f7f7f8;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.chat-sections {
  display: flex;
  justify-content: space-around;
  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.chat-sections p {
  width: 100%;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.chat-sections p::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #3e51ff;
  transition: width 0.3s ease-in-out;
}

/* .chat-sections p:hover::before, */
.chat-sections p.active_tab_class::before {
  width: 100%;
}

.active_tab_class {
  color: #3e51ff;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: color 0.3s ease-in-out;
}

.sender-profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #cadfff;
  padding: 2px;
  object-fit: contain;
}

.sender-name {
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: start;
  text-transform: capitalize;
}

.text-recived {
  color: rgba(0, 0, 0, 0.886);
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  margin-top: -8px;
}

.people-chat-time {
  color: rgba(0, 0, 0, 0.4);
  text-align: right;
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}

.people-chat {
  /* border-top: solid 2px rgba(0, 0, 0, 0.051); */
  margin-top: -18px;
  padding-top: 20px;
  margin-bottom: 18px;
}

.main-chat-header {
  display: flex;
  justify-content: space-between;
}

.main-chat-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: fill;
  border: 2px solid #cadfff;
  padding: 2px;
}

.main-chat-settings {
  width: 36px;
  height: 36px;
}

.text1 {
  width: 192px;
  height: 34px;
  margin-top: 150px;
  margin-left: 30px;
  border-radius: 5px 5px 5px 0px;
  background: #3e97ff;
}

.text1 p {
  color: #fff;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
  text-align: start;
}

.text2 {
  width: 352px;
  height: 65px;
  margin-top: 5px;
  margin-left: 30px;
  border-radius: 16px 16px 16px 0px;
  background: #3e97ff;
}

.text2 p {
  color: #fff;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
  text-align: start;
}

.chat-time {
  color: #fff;
  text-align: right;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  opacity: 0.5;
  margin-top: -10px;
  margin-right: 20px;
}

.message-box-chats {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  height: 60px;
  background: #f7f7f8;
}

.right-cal-event {
  margin-bottom: -15px;
  width: 97px;
  height: 30px;
  padding-top: 5px;
  border-radius: 10px;
  color: #000;
  background: #e5ecf6;
}

.preview-cert-txt {
  color: #000;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.preview-dwld-btn {
  display: inline-flex;
  padding: 10px 20px;
  align-items: center;
  border: none;
  gap: 10px;
  border-radius: 10px;
  background: #3e51ff;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.certificate-img {
  width: 950px;
  height: 675px;
}

.preview-edit-btn {
  display: inline-flex;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #efefef;
  background: #fafafb;
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.template-action {
  width: 1128px;
  height: 50px;
  /* flex-shrink: 0; */
  border-radius: 10px;
  border: 1px solid #e0e2e7;
  background: #f7f7f8;
  color: #4d4d4d;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard-graph {
  width: 100%;
  height: auto;
}

.react-calendar__navigation button {
  min-width: 0px;
  background: none;
}

.templates-leave {
  color: #000;
}

.profile-txt {
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.profile-edit-btn {
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: inline-flex;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: none;
  background: #3e51ff;
}

.profile-info {
  text-align: start;
  color: #000;
  font-family: Nunito;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: -0.05px;
  opacity: 0.9 !important;
}

.profile-data {
  /* margin-top: -12px; */
  text-align: start;
  color: #000;
  font-family: Nunito;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  letter-spacing: -0.06px;
}

.profile-salary-slip {
  color: #3e51ff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.07px;
}

.anouncement-text {
  margin: auto;
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.07px;
  text-align: justify;
}

.new-feedback {
  margin-top: auto;
  margin-bottom: auto;
  color: #999;
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.065px;
}

.announcement-time {
  /* margin-left: 10px; */
  color: #656668;
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.065px;
}

.profile-txt {
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.profile-edit-btn {
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: inline-flex;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: none;
  background: #3e51ff;
}

.profile-info {
  text-align: start;
  color: #000;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.05px;
  opacity: 0.8;
}

/* .profile-data {
  margin-top: 0px;
  text-align: start;
  color: #000;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.06px;
} */

.profile-salary-slip {
  color: #3e51ff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.07px;
}

.profile-card-title {
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.profile-edit-input {
  /* width: 430px; */
  height: 52px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #e0e2e7;
  background: linear-gradient(0deg, #f7f7f8 0%, #f7f7f8 100%), #fff;

  color: rgba(0, 0, 0, 0.9) !important;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 112.4%; /* 15.736px */
}
.password-update-input {
  width: 430px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #e0e2e7;
  background: linear-gradient(0deg, #f7f7f8 0%, #f7f7f8 100%), #fff;

  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 112.4%; /* 15.736px */
}
.password-update-submit {
  margin-top: 40px;
  margin-bottom: 50px;
  width: 430px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #3e51ff;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
}

.profile-edit-submit {
  margin-top: 40px;
  margin-bottom: 100px;
  width: 206px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #3e51ff;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
}

.help-text {
  text-align: start;
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 192.857% */
}

.help-support-btn1 {
  display: inline-flex;
  padding: 8px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #e0e2e7;
  background: #fff;
  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.help-support-btn {
  display: inline-flex;
  height: 36px;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  border: none;
  background: #3e51ff;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.help-support-heading {
  margin-bottom: 0px;
  text-align: start;
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 20px; 125% */
}

.help-support-text {
  text-align: start;
  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.ticket-number {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 87.5% */
}

.ticket-question {
  text-align: start;
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
}

.ticket-post-time {
  width: 150px;
  color: #999;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
}

.ticket-link {
  color: #3e51ff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  text-decoration-line: underline;
}

.help-support-dd-img {
  margin-right: 10px;
  margin-bottom: 3px;
  width: 12px;
  height: 12px;
}

.profile-card-title {
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.profile-edit-input {
  /* width: 430px; */
  height: 52px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #e0e2e7;
  background: linear-gradient(0deg, #f7f7f8 0%, #f7f7f8 100%), #fff;

  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 112.4%; /* 15.736px */
}

.profile-edit-submit {
  margin-top: 40px;
  margin-bottom: 100px;
  width: 206px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #3e51ff;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
}

.help-text {
  text-align: start;
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 192.857% */
}

.help-support-btn1 {
  display: inline-flex;
  padding: 8px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #e0e2e7;
  background: #fff;
  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.help-support-btn {
  display: inline-flex;
  height: 36px;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  border: none;
  background: #3e51ff;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.help-support-heading {
  margin-bottom: 0px;
  text-align: start;
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 20px; 125% */
}

.help-support-text {
  text-align: start;
  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.view-page {
  margin-top: 80px;
  margin-left: 300px;
}
.contact-mini-logo {
  width: 28px;
  height: 28px;
}

.contact-txt {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
  text-align: start;
}

.contact-form-btn {
  width: 515px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 10px;
  border: none;
  background: #3e51ff;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contact-mini-logo {
  width: 28px;
  height: 28px;
}

.contact-txt {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
  text-align: start;
}

.contact-txt1 {
  color: #dd5471;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.32px;
}

.contact-img {
  margin-top: 50px;
  width: 380px;
  height: 412px;
}

.settings-txt {
  text-align: start;
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.commenter-name {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: nowrap;
}

.comment-time {
  color: #999;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  padding-left: 10px;
  display: inline-block !important;
  width: 100% !important;
  text-align: start !important;
}

.comment-avatar {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 40px;
}

.comment-txt {
  text-align: justify;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.reply-btn {
  margin-top: 10px;
  width: 124px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 10px;
  border: none;
  background: #3e51ff;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.reply-btn2 {
  margin-top: 10px;
  width: 100%;
  height: 45px;
  flex-shrink: 0;
  border-radius: 10px;
  border: none;
  background: #3e51ff;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.comment-reply {
  margin: 10px;
  text-align: start;
  width: 570px;
  height: 95px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #3e51ff;
  background: #fff;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.you-btn {
  margin: auto;
  width: 36px;
  height: 19px;
  flex-shrink: 0;
  border-radius: 5px;
  border: none;
  background: #3e51ff;
  color: var(--White, #fff);
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.comment-write {
  text-align: start;
  width: 735px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #3e51ff;
  background: #fff;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.update-comment-btn {
  margin-top: 10px;
  width: 124px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 10px;
  border: none;
  background: #3e51ff;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.delete-comment-btn {
  color: #ff4747;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.comment-inbox {
  width: 600px;
  height: 146px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #eff4fa;
  background: #fff;
}

.modal-user-details {
  width: 900px;
}

.user-view-table {
  margin-left: -16px;
  width: 900px;
}

.view-user-info {
  color: #000;
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.view-user-data {
  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.checkbox-view-user {
  background-color: #000000;
}

.signature-img {
  width: 100%;
  height: auto;
  padding: 5px;
}

.add-img-btn {
  display: inline-flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  border: none;
  background: #dedefa;
  color: #3e51ff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
}

.notify-admin-btn {
  margin-top: 100px;
  width: 206px;
  height: 45px;
  border-radius: 10px;
  border: none;
  background: #3e51ff;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.import-img-card {
  border-radius: 10px;
  border: 2px dashed #e0e2e7;
  background: #f7f7f8;
}

.color-palete {
  display: flex;
  width: 45%;
  height: auto;
}

.otp-input {
  width: 75px;
  height: 50px;
  flex-shrink: 0;
  background: #f7f7f8;
  border-radius: 10px;
  /* border: none; */
  border-color: #e0e2e7;
  border-style: solid;
  text-align: center;
}

.not-recive-otp {
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact-img {
  margin-top: 50px;
  width: 380px;
  height: 412px;
}

.task_table .tr {
  margin: 10px 0 !important;
}

.user_table .tr {
  margin: 50px 0 !important;
  padding: 50px 0 !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.pagination li {
  cursor: pointer !important;
}

.view_user .profile_img {
  height: 120px;
  width: 120px;
  border-radius: 50% !important;
}

.view_user .profile_img img {
  border-radius: 50% !important;
}

/* View employee */

.text_transform_none {
  text-transform: none !important;
}

.view_user {
  font-family: "Nunito" !important;
}
.profile_data .title_head {
  color: black !important;
  font-weight: bold;
  margin: 6px 0;
}

.profile_data .title_value {
  color: #656668 !important;
  text-transform: capitalize;
  font-weight: normal !important;
}

.tickets ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Nunito";
  gap: 20px;
}

.tickets ul li a {
  text-decoration: none !important;
  font-size: 12px;
  color: black;
}

.nav-tabs .nav-link {
  font-weight: bold;
}
.nav-tabs .nav-link:hover {
  border-color: white !important;
}
.nav-tabs .nav-link.active {
  border-color: white !important;
  border-bottom: 2px solid rgb(0, 0, 0) !important;
}

/* APEX PIE CHART 1 */

.charts {
  font-family: "Nunito";
  color: #05103633;
}

.charts span {
  font-size: 35px;
  color: black;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;
}

.pie_chart_text {
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.template-edit-head-dd {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
}
.template-edit-head-arrow {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.edit-temp-font {
  color: #000;
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
}

.edit-menubar-heading {
  text-align: start;
  color: #737578;
  font-feature-settings: "salt" on, "liga" off;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  opacity: 0.5;
}

.menubar-subheads {
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sub-heading-menubar {
  background-color: rgb(91, 146, 255, 0.05);
  padding: 0px;
}

.list-profile-pic {
  width: 24px;
  height: 24px;
  border-radius: 16px;
  margin-left: 10px;
  margin-right: 10px;
}

.table-profile-pic {
  width: 24px;
  height: 24px;
  border-radius: 16px;
  margin: 10px;
}

.dashed_border {
  border: 2px #999 dashed;
  background-color: #f7f7f8;
  border-radius: 10px;
}

.blue_btn {
  padding: 10px 14px;
  border-radius: 10px;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #3e51ff;
  background: #dedefa;
  border: none;
  outline: none;
}

.setting_logo img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.w_100_h_100 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.w_10_h_10 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  object-fit: cover;
}
.w_80_h_80 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #1a81ff;
  margin: 10px 0;
}
.comment-section {
  position: absolute;
  background: white;
  z-index: 10;
}
.progress-bar {
  background: rgba(0, 0, 0, 0.05);
  height: 28px;
  width: 160px;
  margin: 0 0 10px 0;
  border-radius: 8px;
  overflow: hidden;
}

.progress-container {
  position: relative;
  height: 100%;
}

.progress {
  background-color: #c6c7f8;
  color: black;
  font-weight: 600;
  line-height: 28px;
  height: 100%;
}

.progress-label {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 14px;
  transform: translate(-50%, -50%);
  color: #333; /* Choose a color that contrasts well with your progress bar color */
  font-weight: 600;
}
.doc-req-bar {
  border-radius: 0px 4px 4px 0px;
  background: rgba(0, 0, 0, 0.1);
  height: 6px;
  flex-shrink: 0;
}
.w_20_h_20 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px dashed #2b652b7d;
}
.w_30_h_30 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.w_50_h_50 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.profile_icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-right: 5px;
  border-radius: 50%;
  border: 1px solid #76b3fb;
  padding: 3px;
}

.w_50_h_50 {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 5px;
  border-radius: 15px;
}
.task_reply textarea {
  border: #3e51ff dashed 2px;
  border-radius: 5px;
  outline: none;
}

.mdb_table {
  font-family: "Nunito", sans-serif;
  border: 0.8px solid #f2f2f2 !important;
  border-radius: 4px !important;
  margin: 0 !important;
}
.mdb_table_header {
  font-weight: normal !important;
  vertical-align: sub !important;
}
.mdb_table_header2 {
  font-weight: normal !important;
  /* vertical-align: sub !important; */
  margin-left: 8px;
}

.table-responsive {
  overflow-x: auto !important;
}

.eye_container2 {
  top: 30px !important;
}

.mdb_table thead th {
  border: none;
}
.mdb_table td {
  border: none !important;
  padding: 8px 16.5px !important;
}

.mdb_table tbody tr {
  border: none;
}

thead tr th div {
  margin: 0px 0px !important;
}

.mdb_table tbody td {
  border: none;
}

.mdb-datatable-filter {
  position: absolute;
  top: 35px;
  margin-right: 20px;
}
.mdb-datatable-filter input {
  width: 100%;
  height: 100%;
}

.mdb2 .mdb-datatable-filter {
  top: 50px !important;
}
.mdb3 .mdb-datatable-filter {
  top: 105px !important;
}

p + p {
  margin: 0 !important;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding: 12px 6px !important;
}

.sr-only {
  display: none !important;
}

.task_reply textarea {
  border: #3e51ff dashed 2px;
  border-radius: 5px;
  outline: none;
}
.textarea_h_100px {
  min-height: 100px !important;
}
.nav.nav-tabs {
  font-size: 14px !important;
  text-decoration: none !important;
  font-family: "Nunito" !important;
  border: none !important;
}

.nav.nav-tabs .nav-link {
  color: black !important;
}
.custom-progress {
  accent-color: black;
}
.custom-progress2 {
  /* accent-color: #c6c7f8; */
  accent-color: rgb(0, 0, 0);
  height: 50px !important;
}

.custom-progress2 {
  position: relative;
}

.custom-progress2::after {
  content: attr(value) "%" !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #0f1048;
  font-weight: bold;
}

.signature_logos {
  height: 100px;
}

.otp-input-box .form-group {
  display: flex;
  align-items: center;
  justify-content: space-around !important;
}
.otp-input-box .form-group input {
  width: 70px !important;
  height: 50px;
  border: 1px solid #e0e2e7;
  border-radius: 10px;
  background-color: #f7f7f8;
}
.errorText {
  color: red !important;
  font-size: 13px;
}

/* .pass_toggle_text {
} */

.depart_table {
  position: relative;
}
.depart_table .mdb-datatable-filter input {
  width: 100%;
  height: auto;
  position: relative !important;
  top: -6rem;
}
.active_tab {
  text-underline-offset: unset;
  text-decoration: underline;
  cursor: pointer;
}
.cal_tab {
  cursor: pointer;
}
.eye_container {
  position: absolute;
  top: 43px;
  right: 27px;
}

.eye_container .eye_icon {
  cursor: pointer;
  height: 18px;
  width: 16px;
}
.password_field {
  position: relative;
}

.eye_container_signUp {
  position: absolute;
  top: 40px;
  right: 37px;
}

.eye_container_signUp .eye_icon {
  cursor: pointer;
  height: 18px;
  width: 16px;
}
.view-detail-page {
  width: 100%;
  margin-top: 20px;
}
.department-name {
  font-size: 15px;
  color: #051036;
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  line-height: 36px; /* 150% */
}

.text-area {
  height: 5rem;
}
.form-label {
  font-weight: 600 !important;
}

.search_icon {
  position: absolute;
  top: 42px;
  right: 28px;
  z-index: 999 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Sweet alert */
.swal2-title {
  line-height: 1 !important;
}

.input_icons .icons_img {
  position: absolute;
  top: 43px;
  left: 10px;
  object-fit: cover;
  height: 50px;
  width: 20px;
}
.input_icons {
  position: absolute;
  top: 42px;
  left: 18px;
  transform: scale(1.3);
  opacity: 0.75;
  /* border-right: 1px solid grey; */
  padding-right: 3px;
}
.input_icons2 {
  position: absolute;
  top: 35px;
  left: 18px;
  transform: scale(1.3);

  opacity: 0.75;
  /* border-right: 1px solid grey; */
  padding-right: 3px;
}
.input_icon_container {
  position: relative !important;
}
.input_icon_container input {
  padding-left: 2.7rem !important ;
}
.dashboard_graph .apexcharts-title-text {
  display: none !important;
}
.dashboard_graph .apexcharts-legend-series {
  padding: 5px 0 !important;
}
.dashboard_graph .apexcharts-legend {
  top: 0 !important;
}

/* PDF */

.rpv-default-layout__sidebar.rpv-default-layout__sidebar--ltr {
  display: none !important;
  border-right: none !important;
}
/* .rpv-toolbar__right > div:nth-child(4){
  position: absolute !important;
  top: 0;
} */

.rpv-toolbar__right > div:nth-child(1),
.rpv-toolbar__right > div:nth-child(2),
.rpv-toolbar__right > div:nth-child(6),
.rpv-toolbar__right > div:nth-child(3),
.rpv-toolbar__left {
  display: none !important;
}

/* CALENDER */
.rbc-event-content,
.rbc-event-label {
  color: white !important;
}
.rbc-event,
.rbc-event-label {
  color: rgb(0, 0, 0) !important;
}
.rbc-day-slot.rbc-time-column.rbc-now.rbc-today,
.rbc-event-content {
  color: blue !important;
}

.rbc-row-segment .rbc-event-content {
  color: white !important;
}

.rbc-agenda-table tbody {
  color: white !important;
  font-weight: 600 !important;
  font-family: "Nunito" !important;
}

.rbc-header {
  padding-top: 20px !important;
}
.rbc-month-header {
  border-bottom: 1px solid grey;
}
.rbc-date-cell {
  flex: 1 1;
  text-align: center !important;
  padding: 0 !important;
  font-size: 20px;
  font-weight: bold;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
  color: #ff8f6b;
  font-size: 22px;
}

.calendar_container {
  /* background: #00000066; */
  font-family: "Nunito";
}

.rbc-header + .rbc-header {
  border: none !important;
  padding: 20px 3px !important;
  background-color: #dddddd62;
}
.rbc-toolbar {
  position: absolute;
  top: 0;
  width: 100% !important;
}

.rbc-toolbar > span:nth-child(3) {
  display: inline-block;
  white-space: nowrap;
  display: block;
  left: 0;
  position: relative;
  position: absolute;
  /* padding-top: 80px; */
  top: 0;
}

.rbc-month-view {
  margin-top: 0 !important;
  /* margin-top: 1.2rem !important; */
}

.rbc-toolbar > span:nth-child(1) {
  display: inline-block;
  white-space: nowrap;
  right: 0;
  position: absolute;
  top: 38px;
  margin: 0;
  padding: 0;
}

.rbc-toolbar > span:nth-child(2) {
  position: absolute;
  top: 45px;
  left: 0 !important;
}
.rbc-toolbar button {
  border: none !important;
  background-color: white !important;
  box-shadow: none !important;
}
.rbc-toolbar .rbc-active {
  border-bottom: 2px solid rgb(0, 0, 0) !important;
  border-radius: 0;
}
.rbc-events-container .rbc-event {
  background-color: #ebf5ed !important;
  color: #3e51ff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  min-height: 40px !important;
}
.rbc-timeslot-group {
  min-height: 60px !important;
}
.rbc-day-slot .rbc-events-container {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 20px 3px !important;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  color: black;
  background: white;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
  background: white;
  color: black;
}

.demo-editor {
  border-radius: 5px !important;
  border: solid 1px black;
  padding-left: 2px;
  min-height: 300px !important;
}
/* .jodit-status-bar {
  display: none !important;
} */

.jodit-wysiwyg {
  min-height: 450px !important;
}

.PSPDFKit-6s8pjz7ybfkbm9j34ce4qsz32w .PSPDFKit-2h6u1uvepb29aceyswrhabn442,
.PSPDFKit-6s8pjz7ybfkbm9j34ce4qsz32w .PSPDFKit-5hqvpgcgpf1769cn35dvtg4ktz {
  display: none !important;
}

[aria-label="Change mode"],
[aria-label="Speech Recognize"],
[aria-label="Print"],
.PSPDFKit-Toolbar-Button-Search {
  display: none !important;
}

/* .jodit-toolbar__box > div > div:last-child > div:last-child {
  display: none !important;
} */

[data-ref="about"] .jodit-toolbar-button__button {
  display: none !important;
}

.new_bg {
  background-color: #f5f5f5 !important;
}

h1 {
  overflow: hidden;
  text-align: center;
}
.dash_span {
  position: absolute;
  top: -27px;
  left: 50%;
  transform: translateX(-50%) !important;
  display: block;
  background: rgb(255, 255, 255);
  z-index: 5;
  padding: 0 20px;
  font-weight: 600;
  font-size: 17px;
  font-family: "Nunito";
}
.dash {
  position: absolute;
  display: inline-block;
  width: 100%;
  text-align: center;
  top: -16px;
  border: 1px dashed black;
}
.fw_500_nunito {
  font-weight: 500;
  font-family: nunito;
}
.modal-dialog .modal-header {
  font-family: Nunito;
  font-size: 17px;
  font-weight: 600 !important;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
}

label {
  font-family: Nunito;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: #000000;
}
.font_nunito {
  font-family: "Nunito";
}
.helpNSupportDD button {
  min-width: 140px;
}
.help_n_support_reply {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: "Nunito" !important;
  scroll-behavior: smooth !important;
}
.reply_input_container {
  border-radius: 4px;
  border-top: 1px solid rgb(0, 0, 0);
  padding: 10px !important;
  position: sticky;
  bottom: 0;
}
.reply_container p {
  background: rgb(255, 255, 255);
  display: inline-block;
  width: 100%;
  margin: 7px 0 !important;
}
.reply_container p span {
  background: rgb(209, 209, 209);
  padding: 4px 30px;
  border-radius: 10px;
}
.rce-mbox-photo--img img {
  height: 200px !important;
  width: 200px !important;
}
.rce-mbox-body {
  max-width: 300px;
}
.rce-mbox-text {
  font-size: 13.6px;
  word-break: break-word;
  max-width: 300px;
  line-height: 1.5;
}
.rce-avatar-container .rce-avatar {
  border-radius: 50%;
}
.rce-mbox-photo--img img {
  object-fit: contain;
}
/* .rce-mbox-time .rce-mbox-time-block .non-copiable{
  padding: 0 !important;
}
.rce-mbox {
  padding: 15px 0px 15px 20px !important;
} */

.dot {
  color: red;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.red_dot {
  background-color: red;
}
.yellow_dot {
  background-color: orange;
}
.green_dot {
  background-color: green;
}

.middle-content .header ul {
  padding: 0 !important;
}
.middle-content .header ul li {
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
}

.breadcrumbs {
  font-size: 15px !important;
  font-family: "Nunito" !important;
}
.breadcrumbs span:first-child {
  display: none !important;
}
.t_head_box img {
  width: 15px;
  height: 15px;
  opacity: 0.7;
  cursor: pointer;
}
.back-tasks {
  margin-left: 85%;
  color: black;
  font-weight: 400;
  font-family: nunito;
}
.cancel_btn {
  color: #000 !important;
  background-color: #80808047 !important;
}
select {
  -webkit-appearance: menulist !important;
}

/* TABLE CSS */

.table > :not(:first-child) {
  border-top: 0;
}

.mdb_table .table-responsive::-webkit-scrollbar {
  display: block !important;
  width: 10px !important;
  height: 8px !important;
  border-radius: 50% !important;
}
.mdb_table .table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}
.mdb_table .table-responsive::-webkit-scrollbar-thumb {
  background: #76b3fb !important;
  border-radius: 50px !important;
}
.mdb_table .table-responsive::-webkit-scrollbar-thumb {
  background: #76b3fb !important;
  border-radius: 50px;
}
.mdb_table .table-responsive::-webkit-scrollbar-thumb:hover {
  background: #76b3fb !important;
  cursor: pointer !important;
}

.dataTables_wrapper > div:nth-child(2) > div:nth-child(1) {
  padding-bottom: 60px !important;
}

.dataTables_wrapper > div:nth-child(3) {
  position: absolute;
  bottom: 13px;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: end;
}
.dataTables_paginate .pagination {
  gap: 0 !important;
}
.dataTables_paginate {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.dataTables_wrapper .pagination-page.active span {
  display: none !important;
}

.pagination .page-item.active .page-link {
  color: #131111 !important;
  background-color: #f5f5ff !important;
  border-radius: 0.125rem;
  /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); */
  transition: all 0.2s linear;
}

div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_paginate {
  padding-top: 1rem;
  /* padding-bottom: 1rem; */
  padding-left: 1rem !important;
  font-weight: 400;
}
.dataTables_wrapper > div:nth-child(3) > div:nth-child(1) {
  position: absolute !important;
  width: 100% !important;
}
.create_group_inputs {
  font-family: "Nunito";
}
.create_group_inputs input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  background-color: rgba(255, 255, 255, 0) !important;
  outline: none;
  margin-top: 7px;
  font-size: 14px;
}
.select__input-container.css-qbdosj-Input {
  /* display: none; */
  opacity: 0 !important;
}
.user_icon_listing {
  margin: 0 -5px !important;
}
.assets_modal {
  top: -23px;
  border: 1px dashed grey;
  background: white;
  border-radius: 3px;
  width: 400px;
  z-index: 5;
  min-height: 300px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px !important;
  right: 99px;
}
.right_side_bar {
  padding-left: 3px;
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 240px;
}
.notification_modal {
  height: 400px;
  width: 500px;
  background-color: rgb(247 247 247);
  z-index: 5555;
  position: relative;
  top: 25px !important;
  border: 1px solid rgb(54, 54, 54);
  border-radius: 13px;
  font-family: nunito;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 10px;
}
.notification_modal .title {
  font-family: Nunito;
  font-size: 17px;
  font-weight: 600 !important;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
}

.fontStyling input,
.fontStyling select {
  border: 1px #313030 solid !important;
}
.user_table_edit .rs-checkbox-checker {
  /* min-height: 20px !important; */
  padding: 0 !important;
}
.user_table_edit td,
.user_table_edit tr {
  padding: 0 !important;
  border: none !important;
}
.jodit-ui-group_line_true {
  background-color: rgba(235, 245, 237) !important;
}
.jodit-ui-group_separated_true:not(:last-child):not(
    .jodit-ui-group_before-spacer_true
  ):after {
  display: none !important;
}
.check_toggle label {
  position: relative;
  display: inline-block;
  font-weight: 400;
  color: #fff;
  font-size: 14px;
  padding-right: 27px;
}
.check_toggle label::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 38px;
  height: 18px;
  background-color: #cacaca;
  border-radius: 10px;
  transition: 0.5s all;
}
.check_toggle input:checked ~ label::before {
  right: 3px;
  background-color: #fff;
}
.check_toggle {
  width: 100%;
  margin-top: -26px;
  margin-left: 10px;
}
.check_toggle label::before {
  position: absolute;
  content: "";
  top: 2px;
  right: 21px;
  width: 14px;
  height: 14px;
  background-color: #ffffff;
  border-radius: 50px;
  z-index: 1;
  transition: 0.5s all;
  cursor: pointer !important;
}
.check_toggle input:checked ~ label::after {
  background-color: rgb(62 116 255 / 82%);
  cursor: pointer !important;
}

.preview_close_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  /* width: 20px; */
  /* height: 20px; */
  color: white;
  background-color: black;
  /* padding-left: 6px; */
  border-radius: 50%;
  /* padding-top: 18px; */
  padding: 1px 6px;
  transform: translate(-50%, -50%);
}

.chart_container {
  position: relative;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background: white;
  border: 50px solid #c6c7f8;
  box-shadow: 0px 0px 4px #0091ff;
}
.chart_container .no_data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Nunito;
}

.jodit-toolbar-button .jodit-icon {
  height: 15px !important;
  width: 15px !important;
  color: #000 !important;
}
.jodit-container:not(.jodit_inline) {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px !important;
}
.jodit-ui-group {
  background: #fff !important;
}
.default-icons {
  position: absolute;
  top: 10px;
  right: 222px;
  gap: 12px;
  z-index: 999;
}

.editor-img {
  height: 20px !important;
  width: 20px !important;
}
.jodit-workplace {
  height: calc(100vh - 230px) !important;
}

[data-ref="image"] {
  display: none !important;
}

/* .box_shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
} */

.template_side_icon {
  /* width: 30px;
  height: 30px; */
  cursor: pointer;
  font-size: 25px;
  color: #4c4c4c;
  margin: 12px 4px 12px 8px;
  opacity: 0.75;
}
.template_side_icon.active {
  color: #3498ff;
  opacity: 0.9;
}
.editor_input_fields .text {
  border: 1px dashed black;
  border-radius: 7px;
  padding: 1px 6px;
  font-weight: 700;
  margin-right: 10px;
}
.editor_input_fields p {
  cursor: pointer;
  padding: 5px;
  margin: 10px 0 !important;
  border: 1px dashed #ffffff;
}
.editor_input_fields p:hover {
  border-radius: 5px;
  border: 1px dashed #1a81ff;
  /* padding: 5px ; */
  background-color: #b3d4fc5c;
}
.accordion-button {
  padding: 0px 20px !important;
}
.accordion-button:not(.collapsed) {
  background-color: white !important;
}

.unread {
  background-color: #e3f5ff !important;
}

.user_table td {
  border: none !important;
}

.text_justify {
  text-align: justify !important;
}
.file_preview {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  border: none !important;
}
.file_preview::-webkit-scrollbar {
  display: none !important;
}
.preview_pdf {
  position: absolute;
  top: 8px;
  left: 16px;
  width: 30px;
  height: 30px;
  color: white;
  background-color: black;
  /* padding-left: 6px; */
  border-radius: 50%;
  /* padding-top: 18px; */
  padding: 1px 6px;
  transform: translate(-50%, -50%);
  z-index: 5555 !important;
}
iframe {
  border: 0 !important;
}
[aria-label="Print"] {
  display: none !important;
}
.w_10_h_10 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  object-fit: cover;
}
.react-pdf__Page__annotations.annotationLayer,
.react-pdf__Page__textContent.textLayer {
  display: none !important;
}
.guide_editor .ql-editor{
  min-height: 60vh !important;
}

.char_count{
  right: 18px !important;
  bottom: 10px !important;
}
.ticket_card{
  border-radius: 10px;
  border: 1px solid rgb(193, 188, 188);
  background-color: white;
  color: black;
  font-family: Nunito;
  /* height: 100%; */
  padding: 10px 0;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.ticket_card .ticket_card_title{
  font-size: 12px !important;
  font-weight: bold;
  padding: 0 10px;
  text-wrap: wrap;
  text-align: center;
}
.ticket_card .ticket_count{
  font-size: 18px;
  font-weight: 700 !important;
  text-align: center;
  color: #02a9ff !important;
}