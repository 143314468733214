/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

:root {
    scroll-behavior: smooth;
  }
  
  
  body,
  html {
    font-family: 'Nunito', sans-serif;
    overflow: hidden;   
  }
  
  p {
    font-weight: 400;
  }
  
  a {
    color: #243A73;
    text-decoration: none;
    transition: 0.5s all;
  }
  
  a:hover {
    color: #000000;
    text-decoration: none;
  }
  
  html,
  body {
    scroll-behavior: smooth;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Nunito', sans-serif;
  }
  .ellipsis-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis
  }
   
  /*--------------------------------------------------------------
  # Sidebar css 
  --------------------------------------------------------------*/
  .superadmin {
    display: flex;
  }
  .siderbar_part {
    width: 16%;
    background-color: #fff;
  }
  .superadmin_main{
    width: 62%; 
  }
  .notifications_part {
    width: 22%;
    padding: 24px 20px;
    border-left: 1px solid rgba(229, 229, 229, 1);
  }
  .logo_part {
    padding: 20px 28px 50px;
  }
  .siderbar_menus {
    padding: 0 14px 20px;
    height: calc(100vh - 128px);
    overflow-y: auto;
  }
  .siderbar_menus h2 {
    font-size: 13px;
    font-weight: 500;
    color: rgba(115, 117, 120, 1);
    margin-bottom: 12px;
  }
  .siderbar_menus ul {
    list-style: none;
    padding: 0;
  }
  .siderbar_menus ul li a {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 400;
    height: 40px;
    transition: 0.3s all;
  }
  .sicon {
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .siderbar_menus ul li a.active {
    color: rgba(62, 81, 255, 1);
    font-weight: 500;
    background-color: rgba(235, 245, 237, 1);
    border-radius: 8px;
  }
  .siderbar_menus ul li a.active .sicon img{
    filter: unset;
  }
  .sicon img {
    max-width: 20px;
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(224%);
  }
  .siderbar_menus ul li a .ellipsis-1{
    width: 70%;
  } 
  .notification_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .notification_top h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
  .bg_notification1{
    background-color: rgba(227, 245, 255, 1);
  }
  .bg_notification2{
    background-color: rgba(229, 236, 246, 1);
  }
  .notification_box {
    display: flex;
    align-items: flex-start;
    margin: 14px 0;
    transition: 0.3s all;
  }
  .notification_box:hover{
    transform: translateY(-5px);
  }
  .notification_icon {
    width: 26px;
    height: 26px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .notification_details h3 {
    font-weight: 400;
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
    margin: 0;
    line-height: 20px;
  }
  .notification_details span {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 400;
    display: block;
  }
  .notification_left {
    width: 15%;
    text-align: center;
  }
  .notification_details {
    padding-left: 5px;
    width: 85%;
  }
  .notification_icon img {
    max-width: 14px;
  }
  .notification_main {
    height: calc(100vh - 85px);
    overflow-y: auto;
  }
  .notification_main::-webkit-scrollbar{
    display: none;
  }
  .header_part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    border-bottom: 1px solid rgba(229, 229, 229, 1);
  }
  .header_profile .dropdown button {
    background-color: transparent;
    border: palegoldenrod;
    padding: 0 30px;
    display: flex;
    align-items: center;
    box-shadow: unset !important;
  }
  .header_profile .dropdown-menu {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    border: 0;
    border-radius: 8px;
    right: 30px !important;
    left: auto !important;
    top: 15px !important;
    padding: 5px 8px;
  }
  .header_profile .dropdown-menu .dropdown-item img {
    max-width: 15px;
    margin-right: 10px;
  }
  .header_profile .dropdown-menu .dropdown-item {
    font-size: 14px;
    font-weight: 400;
    color: rgba(28, 28, 28, 1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 9px 10px;
    display: flex;
    align-items: center;
    background-color: transparent !important;
    transition: 0.3s all;
  }
  .header_profile .dropdown-menu .dropdown-item:hover{
    transform: translateY(-5px);
  }
  .header_profile .dropdown-menu li:last-child .dropdown-item{
    border: 0;
  }
  .propic{
    height: 42px;
    width: 42px;
    border-radius: 50px;
  }
  .propic img{
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  .header_profile .dropdown button span {
    font-size: 16px;
    color: rgba(5, 16, 54, 1);
    display: inline-block;
    margin: 0 12px;
    font-weight: 500;
  }
  .profile_part {
    display: flex;
    align-items: center;
  }
  .header_notification button {
    padding: 0;
    background-color: transparent !important;
    border: 0;
    box-shadow: unset !important;
  }
  .breadcrumb_part .breadcrumb-item a {
    font-size: 14px;
    font-weight: 400;
    color: rgba(5, 16, 54, 1);
  }
  .breadcrumb-item+.breadcrumb-item::before { 
    color: rgba(5, 16, 54, 0.2); 
  }
  .breadcrumb_part {
    padding-left: 40px;
  }
  .superadmin_inner {
    height: calc(100vh - 72px);
    overflow-y: auto;
    padding: 25px 25px;
  }
  .superadmin_inner::-webkit-scrollbar , .siderbar_menus::-webkit-scrollbar{
    display: none;
  }
  .statics_box {
    background-color: rgba(227, 245, 255, 1);
    border-radius: 10px;
    padding: 25px 20px;
  }
  .statics_box span {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    display: block;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .statics_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .statics_inner strong {
    font-weight: 700;
    display: block;
    color: rgba(0, 0, 0, 1);
    font-size: 24px;
  }
  .grphhh {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    justify-content: flex-end;
  }
  .grphhh img {
    margin-left: 6px;
  }
  .top_statics .col-md-3:nth-child(even) .statics_box {
    background-color: rgba(229, 236, 246, 1);
  }
  .comman_table tr th {
    font-size: 12px;
    font-weight: 500;
    padding: 12px 8px;
    color: rgba(0, 0, 0, 0.4);
    border: 0;
    white-space: nowrap;
  }
  .table>:not(:first-child) {
    border-top: 0;
  }
  .comman_table {
    margin-top: 20px;
  }
  .comman_table tr td {
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 1);
    padding: 12px 8px;
    border: 0;
    white-space: nowrap;
  }
  .comman_table .table tbody tr:nth-child(even) {
    background-color: rgba(250, 250, 250, 1);
  }
  .table_dropdown button {
    padding: 0;
    border: 0;
    box-shadow: unset !important;
    background-color: transparent !important;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .comman_table thead tr th:last-child , .comman_table .table tbody tr td:last-child {
    text-align: center;
  }
  .table_dropdown .dropdown-menu {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    border: 0;
    border-radius: 8px;
    right: -10px !important;
    left: auto !important;
    top: 5px !important;
    padding: 5px 8px;
  }
  .table_dropdown .dropdown-menu .dropdown-item {
    font-size: 14px;
    font-weight: 400;
    color: rgba(28, 28, 28, 1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 9px 10px;
    display: flex;
    align-items: center;
    background-color: transparent !important;
    transition: 0.3s all;
  }
  .table_dropdown .dropdown-menu li:last-child .dropdown-item {
    border: 0;
  }
  .table_dropdown .dropdown-menu .dropdown-item img {
    max-width: 15px;
    margin-right: 10px;
  }
  .table_dropdown .dropdown-menu .dropdown-item:hover {
    transform: translateY(-5px);
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(235, 245, 237, 1); 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(235, 245, 237, 1); 
  }
  
  .paginations {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .numbeer a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 28px;
    width: 28px;
    margin: 0 2px;
    font-size: 14px;
    font-weight: 400;
  }
  .numbeer a.active , .numbeer a:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  .paginations a img {
    max-width: 6px;
  }
  .numbeer {
    margin: 0 18px;
  }
  .table_head h3 {
    font-size: 14px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    margin: 0;
  }
  .table_button a {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    font-weight: 400;
  }
  .table_button a img {
    max-width: 13px;
    margin-right: 5px;
  }
  .tabel_bar {
    background: rgba(250, 250, 250, 1);
    border-radius: 8px;
    padding: 7px 6px;
    margin-bottom: 6px;
  }
  .tabel_bar_nav a {
    display: inline-block;
    margin-right: 12px;
  }
  .tabel_bar_nav a img {
    max-width: 21px;
  }
  .selectedd span {
    display: block;
    font-size: 14px;
    font-weight: 400;
  }
  .selectedd {
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    padding: 3px 0 3px 14px;
    margin-left: 15px;
  }
  .tabel_bar_nav {
    margin-top: -2px;
  }
  .search_bar .form-control {
    border: 1.8px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: 30px;
    box-shadow: unset !important;
    color: rgba(0, 0, 0, 0.2);
    font-weight: 400;
    padding-left: 24px;
    width: 150px;
  }
  .search_bar .form-control::placeholder{
    color: rgba(0, 0, 0, 0.2);
  }
  .search_bar .search_button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
  }
  .comman_modal .modal-dialog {
    max-width: 670px;
  }
  .comman_modal .modal-content { 
    border-radius: 8px;
    border: 1px solid rgba(239, 244, 250, 1);
  }
  .comman_modal_head h2 {
    font-size: 17px;
    font-weight: 600;
    margin: 0;
    color: rgba(0, 0, 0, 1);
  }
  .comman_modal_head {
    padding: 20px 20px;
  }
  .comman_modal_head {
    padding: 20px 20px;
    border-bottom: 1px solid rgba(239, 244, 250, 1);
  }
  .comman_form {
    padding: 20px 20px;
  }
  .comman_form .form-control {
    border: 1px solid rgba(239, 244, 250, 1);
    border-radius: 8px;
    height: 36px;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    box-shadow: unset !important;
  } 
  .comman_form .form-group {
    margin-bottom: 18px;
  }
  .comman_form .form-group  .form-select {
    border: 1px solid rgba(239, 244, 250, 1);
    border-radius: 8px;
    height: 36px;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    box-shadow: unset !important;
  }
  .comman_form .form-control::placeholder , .comman_form .form-group .form-select::placeholder{
    color: rgba(153, 153, 153, 1);
  }
  .button_comman {
    height: 28px;
    border: 0;
    background: rgba(62, 81, 255, 1);
    color: #fff;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 31px;
  }
  .cancel_btn {
    font-size: 13px;
    font-weight: 400;
    color: rgba(143, 155, 179, 1);
    padding: 0 10px;
    display: inline-block;
  }
  .company_view {
    padding: 20px 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .company_detailss {
    width: 50%;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.4);
  }
  .company_detailss strong {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
  }

  .sidebar-item li a span{
    font-size: 15px !important; /* Adjust the font size as needed */
  }