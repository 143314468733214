.accordion-wrapper {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
  /* width: 600px; */
  padding: 10px 20px;
  margin: 0 auto;
}
.accordion {
  width: 100%;
  color: white;
  overflow: hidden;
  margin-bottom: 16px;
  border-left: 4px solid rgb(120 200 136) !important;
}
.accordion:last-child {
  margin-bottom: 0;
}
.accordion-label {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: rgb(252 252 252);
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
  opacity: 1;
  color: #212122;
}
.accordion-label:hover {
  background: rgb(210, 243, 216);
}
.accordion-label::after {
  content: "\276F";
  width: 16px;
  height: 16px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.accordion-content {
  max-height: 0;
  padding: 0 16px;
  color: rgba(4, 57, 94, 1);
  background: white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.accordion-content p {
  margin: 0;
  color: rgba(4, 57, 94, 0.7);
  font-size: 18px;
}
.accordion-wrapper input:checked + .accordion-label {
  /* background: rgb(210, 243, 216); */
  background-color: rgb(252 252 252);
}
.accordion-wrapper input:checked + .accordion-label::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.accordion-wrapper input:checked ~ .accordion-content {
  max-height: 100vh;
  padding: 16px;
}
.accordion-wrapper input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
